@import url(https://fonts.googleapis.com/css?family=Montserrat:400,500,600);
@import url(https://fonts.googleapis.com/css?family=Open+Sans:400,600);
body {
    margin: 0;
    padding: 0;
    font-family: "Open Sans", sans-serif;
}

h1,
h2,
h3,
h4 {
    font-family: "Montserrat", sans-serif;
    font-weight: 600;
    margin-top: 0;
}

a {
    text-decoration: none;
    color: currentColor;
}

